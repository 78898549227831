import { useEffect, useMemo, useState } from "react";

import { Form, Input, Modal, Select } from "antd";

import type { Organization } from "@omi-lab/ceos-typescript";

import { useClientsStore } from "../../../../src/store/clients";

type Props = {
  isOpen: boolean;
  onCancel: () => void;
};

const sanitizeString = (str: string) => {
  const str2 = str.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/]/gi, "");
  return str2.replace(/ /g, "_");
};

const ObjectCollectionAppendModal = (props: Props) => {
  const [objectCollectionsClient, organizationsClient] = useClientsStore(
    (store) => [store.objectCollectionsClient, store.organizationsClient],
  );

  const [organizations, setOrganizations] = useState([] as Organization[]);
  const [organizationsFilter, setOrganizationsFilter] = useState("");

  const [values, setValues] = useState({
    organizationId: "",
    visibility: "public",
    organizationName: "",
    name: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const isValid = useMemo(() => {
    if (values.visibility === "private") {
      return !!values.organizationId && !!values.name;
    }
    return !!values.name;
  }, [values]);
  const handleSubmit = async () => {
    setIsLoading(true);
    if (!isValid) return;

    const path = `${sanitizeString(values.organizationName).toLowerCase()}_${sanitizeString(values.name).toLowerCase()}_${values.organizationId}`;

    await objectCollectionsClient
      .createObjectCollection({
        body: {
          name: values.name!,
          path,
          visibility: values.visibility,
          organizationId: values.organizationId ? values.organizationId : null,
        },
      })
      .then(() => {
        setIsLoading(false);
        setValues({
          organizationId: "",
          visibility: "public",
          organizationName: "",
          name: "",
        });
        props.onCancel();
      });
  };

  useEffect(() => {
    const listOrganizations = () =>
      organizationsClient
        .listOrganizations({
          page: 1,
          pageSize: 50,
          nameIncludes: organizationsFilter,
        })
        .then((response) => setOrganizations(response.data));

    listOrganizations();
  }, [organizationsFilter, setOrganizations, organizationsClient]);

  return (
    <Modal
      title={"Create a new collection"}
      open={props.isOpen}
      onOk={() => handleSubmit()}
      okButtonProps={{ disabled: !isValid || isLoading }}
      onCancel={props.onCancel}
      confirmLoading={isLoading}
    >
      <Form.Item name="organizations">
        <Select
          showSearch
          style={{ width: "100%" }}
          placeholder="Search for an organization"
          defaultActiveFirstOption={false}
          showArrow={false}
          filterOption={false}
          onSearch={(value) => setOrganizationsFilter(value)}
          onChange={(value) => {
            const organization: Organization = JSON.parse(value);
            setValues({
              ...values,
              organizationId: organization.id,
              organizationName: organization.name,
            });
          }}
          value={values.organizationId}
        >
          {organizations.map((organization) => (
            <Select.Option
              key={organization.id}
              value={JSON.stringify(organization)}
            >
              {organization.name} - {organization.id}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="visibility">
        <Select
          showSearch
          placeholder="Set visibility"
          onSelect={(visibility: string) => {
            setValues({ ...values, visibility });
          }}
        >
          {["public", "private", "hidden"].map((visibility) => (
            <Select.Option key={visibility} value={visibility}>
              {visibility}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="name">
        <Input
          placeholder="Give your collection a name"
          name="name"
          onChange={(e) => setValues({ ...values, name: e.target.value })}
          value={values.name}
        />
      </Form.Item>
    </Modal>
  );
};

export default ObjectCollectionAppendModal;
