import { useEffect, useState } from "react";

import type { Subscription } from "@omi-lab/cresus-typescript";

import { showErrorNotification } from "src/utils/error";

import { useClientsStore } from "../../../store/clients";

export function useGetActiveSubscription(organizationId?: string) {
  const [subscription, setSubscription] = useState<Subscription>();
  const [isLoading, setIsLoading] = useState(false);

  const [subscriptionsClient] = useClientsStore((store) => [
    store.subscriptionsClient,
  ]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        if (!organizationId) {
          return;
        }

        const { data } = await subscriptionsClient.listSubscriptionsV2({
          organizationId: [organizationId],
          isActive: true,
          returnRelatedProductInstance: true,
        });

        if (data.data.length > 0) {
          setSubscription(data.data[0]);
        }
      } catch (error: unknown) {
        showErrorNotification(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [subscriptionsClient, organizationId]);

  return { subscription, setSubscription, isLoading };
}
