import type { FC } from "react";
import { useEffect, useState } from "react";

import {
  DatePicker,
  Descriptions,
  Form,
  Input,
  Modal,
  notification,
  Select,
  Space,
  Typography,
} from "antd";
import dayjs from "dayjs";
import { useFormik } from "formik";
import * as Yup from "yup";

import { Currency } from "@omi-lab/cresus-typescript";
import type {
  CreateQuoteBody,
  Product,
  Quote,
} from "@omi-lab/cresus-typescript";

import { currencyToSymbol } from "src/utils/currency";
import { showErrorNotification } from "src/utils/error";

import { useClientsStore } from "../../../store/clients";
import { useGetActiveSubscription } from "../hooks/useGetActiveSubscription";
import { useGetProductInstanceQuote } from "../hooks/useGetProductInstanceQuote";
import { useListCoupons } from "../hooks/useListCoupons";
import { useListProducts } from "../hooks/useListProducts";

import { OrganizationEmailsSearch } from "./OrganizationEmailSearch";
import { OrganizationSearch } from "./OrganizationSearch";
import { QuoteCreateAddon } from "./QuoteCreateAddon";

interface Props {
  close: (quote?: Quote) => void;
}

export const QuoteCreate: FC<Props> = (props) => {
  const [nameIncludes, setNameIncludes] = useState<string>();
  const [product, setProduct] = useState<Product>();
  const [sendEmails, setSendEmails] = useState<boolean>(false);

  const { products, isLoading: isLoadingProducts } = useListProducts({
    nameIncludes: nameIncludes,
    page: 1,
    pageSize: 50,
  });
  const { coupons, isLoading: isLoadingCoupons } = useListCoupons({
    page: 1,
    pageSize: 50,
  });

  const [isLoading, setIsLoading] = useState(false);

  const [quotesClient] = useClientsStore((store) => [store.quotesClient]);

  const createQuote = async () => {
    try {
      setIsLoading(true);

      const quote = await quotesClient
        .createQuote({
          body: values,
          returnRelatedProductInstance: true,
          returnRelatedCoupon: true,
          sendEmails,
        })
        .then((response) => response.data);

      notification.success({
        message: "Successfully created the quote.",
      });
      props.close(quote);
    } catch (error: unknown) {
      showErrorNotification(error);
      props.close();
    } finally {
      setIsLoading(false);
    }
  };

  const { values, setValues, handleSubmit, isValid, setFieldValue } = useFormik(
    {
      initialValues: {
        organizationId: undefined,
        product: {},
        couponId: undefined,
        expiresAt: undefined,
        emails: null,
      } as CreateQuoteBody,
      validationSchema: Yup.object({
        organizationId: Yup.string().optional().nullable(),
        currency: Yup.string().oneOf(Object.values(Currency)).required(),
        product: Yup.object({
          productId: Yup.string().required(),
          unitCount: Yup.number().min(1).required(),
          addons: Yup.object({
            productId: Yup.string().required(),
            unitCount: Yup.number().optional().nullable(),
            targets: Yup.array(Yup.string().required()).optional().nullable(),
          })
            .optional()
            .nullable(),
        }).required(),
        couponId: Yup.string().optional().nullable(),
        expiresAt: Yup.date().optional().nullable(),
        emails: Yup.array(Yup.string().email()).nullable(),
      }),
      validateOnMount: true,
      onSubmit: createQuote,
    },
  );

  const { instance, isLoading: isLoadingInstance } = useGetProductInstanceQuote(
    {
      productId: values.product?.productId,
      unitCount: values.product?.unitCount || undefined,
      currency: values.currency,
      addons: values.product.addons?.map((addon) => ({
        productId: addon.productId,
        unitCount: addon.unitCount || 0,
      })),
    },
  );

  const { subscription } = useGetActiveSubscription(
    values.organizationId || undefined,
  );

  useEffect(() => {
    if ((!values.emails || !values.emails.length) && sendEmails) {
      setSendEmails(false);
    }
  }, [values.emails, sendEmails]);

  useEffect(() => {
    setFieldValue("emails", undefined);
  }, [values.organizationId, setFieldValue]);

  return (
    <Modal
      width="50%"
      open
      onCancel={() => props.close()}
      okText="Create quote"
      onOk={() => handleSubmit()}
      okButtonProps={{
        disabled: !isValid || isLoadingInstance,
        loading: isLoading,
      }}
    >
      <Descriptions title="Details" size="default"></Descriptions>
      <Form labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
        <Form.Item label="Organization">
          <OrganizationSearch
            onClear={() =>
              setValues((values) => ({ ...values, organizationId: undefined }))
            }
            onChange={(id) =>
              setValues((values) => ({ ...values, organizationId: id }))
            }
          />
        </Form.Item>
        <Form.Item label="Emails">
          <OrganizationEmailsSearch
            selectedEmails={values.emails || []}
            setSelectedEmails={(emails: string[]) =>
              setFieldValue("emails", emails.length === 0 ? undefined : emails)
            }
            organizationId={values.organizationId ?? ""}
          />
        </Form.Item>
        {values.emails && values.emails.length > 0 ? (
          <Form.Item label="Send emails ?">
            <Input
              type="checkbox"
              onChange={(e) => setSendEmails(e.target.checked)}
            />
          </Form.Item>
        ) : null}
        <Form.Item label="Product">
          <Select
            showSearch
            searchValue={nameIncludes}
            onSearch={setNameIncludes}
            filterOption={false}
            allowClear
            onClear={() => {
              setProduct(undefined);
              setNameIncludes(undefined);
            }}
            loading={isLoadingProducts}
            onChange={(option) => {
              const product = products.find(({ id }) => id === option);
              if (product) {
                setProduct(product);
                setValues((values) => ({
                  ...values,
                  planId: undefined,
                  product: {
                    ...values.product,
                    productId: option as string,
                    unitCount: values.product?.unitCount || 0,
                    addons: [],
                  },
                  couponId: values.couponId || product.defaultCoupon?.id,
                }));
              }
            }}
          >
            {products.map((product, index) => (
              <Select.Option value={product.id} key={product.id}>
                {index} - {product.description || product.name} (
                {product.visibility} - Every {product.billingInterval}{" "}
                {product.billingIntervalUnit})
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="SKUs">
          <Input
            type="number"
            defaultValue={values.product?.unitCount || undefined}
            value={values.product?.unitCount || undefined}
            onChange={(e) =>
              setValues({
                ...values,
                product: {
                  ...values.product,
                  productId: values.product?.productId || "",
                  unitCount: parseInt(e.target.value),
                  addons: (values.product?.addons || []).map((addon) => ({
                    ...addon,
                    unitCount: parseInt(e.target.value),
                  })),
                },
              })
            }
          />
        </Form.Item>
        <Form.Item label="Addons" name="addons">
          <Space direction="vertical" size="large">
            {product &&
              (product?.addons || []).map((addon) => (
                <QuoteCreateAddon
                  key={addon.id}
                  addon={addon}
                  product={values.product}
                  instance={values.product?.addons?.find(
                    ({ productId }) => productId === addon.id,
                  )}
                  add={(params) =>
                    setValues({
                      ...values,
                      product: {
                        ...(values.product || {
                          unitCount: 0,
                          productId: product.id,
                        }),
                        addons: [
                          ...(values?.product?.addons?.filter(
                            (a) => a.productId !== addon.id,
                          ) || []),
                          {
                            productId: addon.id,
                            unitCount: params.unitCount,
                            targets: params.targets,
                          },
                        ],
                      },
                    })
                  }
                  remove={() =>
                    setValues({
                      ...values,
                      product: {
                        ...(values.product || {
                          unitCount: 0,
                          productId: product.id,
                        }),
                        addons: values?.product?.addons?.filter(
                          (a) => a.productId !== addon.id,
                        ),
                      },
                    })
                  }
                  update={(params) =>
                    setValues({
                      ...values,
                      product: {
                        ...(values.product || {
                          unitCount: 0,
                          productId: product.id,
                        }),
                        addons: values?.product?.addons?.map((a) =>
                          a.productId === addon.id
                            ? {
                                productId: addon.id,
                                unitCount: params.unitCount,
                                targets: params.targets,
                              }
                            : a,
                        ),
                      },
                    })
                  }
                />
              ))}
          </Space>
        </Form.Item>
        <Form.Item label="Currency">
          <Select
            value={values.currency || undefined}
            allowClear
            onClear={() => {
              setValues((values) => ({
                ...values,
                currency: undefined,
              }));
            }}
            onChange={(option) => {
              setValues((values) => ({
                ...values,
                currency: option,
              }));
            }}
          >
            {product?.prices?.map(({ currency }) => (
              <Select.Option value={currency} key={currency}>
                {currency.toUpperCase()}
              </Select.Option>
            ))}
          </Select>
          {subscription?.productInstance?.currency &&
          values.currency &&
          subscription.productInstance.currency !== values.currency ? (
            <Typography.Text type="danger">
              This customer already seems to have a subscription with a
              different currency which will conflict. You should cancel it
              first, otherwise they will not be able to subscribe.
            </Typography.Text>
          ) : null}
        </Form.Item>
        <Form.Item label="Coupon">
          <Select
            value={values.couponId || undefined}
            allowClear
            onClear={() => {
              setValues((values) => ({
                ...values,
                couponId: undefined,
              }));
            }}
            loading={isLoadingCoupons}
            onChange={(option) => {
              setValues((values) => ({
                ...values,
                couponId: option as string,
              }));
            }}
          >
            {coupons.map((coupon) => (
              <Select.Option value={coupon.id} key={coupon.id}>
                {coupon.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Expires at">
          <DatePicker
            value={(values.expiresAt && dayjs(values.expiresAt)) || undefined}
            showTime={{ format: "HH:mm" }}
            onChange={(_, str) =>
              setValues((values) => ({
                ...values,
                expiresAt: Array.isArray(str) ? str.join("") : str,
              }))
            }
          />
        </Form.Item>
      </Form>
      {instance ? (
        <Descriptions bordered title="Summary" size="default">
          <Descriptions.Item label="Base" span={3}>
            {((instance.price || 0) / 100).toFixed(2)}
            {currencyToSymbol(values.currency || Currency.Eur)}
          </Descriptions.Item>
          {instance.addons?.map((addon) => (
            <Descriptions.Item
              key={addon.product?.name}
              label={`${addon.product?.name}`}
              span={3}
            >
              {((addon.price || 0) / 100).toFixed(2)}
              {currencyToSymbol(values.currency || Currency.Eur)}
            </Descriptions.Item>
          ))}
          <Descriptions.Item label="Base + addons" span={3}>
            {((instance.priceWithAddons || 0) / 100).toFixed(2)}
            {currencyToSymbol(values.currency || Currency.Eur)}
          </Descriptions.Item>
          {coupons?.find(({ id }) => id === values?.couponId)?.amountOff && (
            <Descriptions.Item label="Discount" span={3}>
              {coupons
                ?.find(({ id }) => id === values?.couponId)
                ?.amountOff?.toFixed(2)}
              €
            </Descriptions.Item>
          )}
          {coupons?.find(({ id }) => id === values?.couponId)?.percentOff && (
            <Descriptions.Item label="Discount" span={3}>
              {coupons
                ?.find(({ id }) => id === values?.couponId)
                ?.percentOff?.toFixed(2)}
              %
            </Descriptions.Item>
          )}
        </Descriptions>
      ) : null}
    </Modal>
  );
};
