import {
  PlanFeatureName,
  ProductSlug,
  ProductType,
} from "@omi-lab/cresus-typescript";

export const productTypeToHumanReadable = (type: ProductType) => {
  switch (type) {
    case ProductType.Sku:
      return "Per SKU.";
    case ProductType.SkuAddon:
      return "Fixed price addon. Applies to all the SKUs.";
    case ProductType.SkuAddonTiered:
      return "Tiered price addon. Applies to all the SKUs.";
    case ProductType.SkuAddonUnits:
      return "Units based addon. Does not correlate with SKUs (eg. 360s).";
  }
};

export const productSlugToHumanReadable = (type: ProductSlug) => {
  switch (type) {
    case ProductSlug.PerSku:
      return "Per SKU";
    case ProductSlug.Videos:
      return "Videos";
    case ProductSlug.Print:
      return "Print";
    case ProductSlug._360s:
      return "360s";
    case ProductSlug.Artwork:
      return "Artwork";
  }
};

export const productSlugDefaultFeatures = (type: ProductSlug) => {
  switch (type) {
    case ProductSlug.PerSku:
      return [
        PlanFeatureName.GeneratorcustomRatio,
        PlanFeatureName.ManageselfProductOnboarding,
        PlanFeatureName.StudiopremiumResources,
        PlanFeatureName.Studiophotos,
      ];
    case ProductSlug.Videos:
      return [
        PlanFeatureName.StudioproductVideos,
        PlanFeatureName.Generatoranimations,
      ];
    case ProductSlug.Print:
      return [PlanFeatureName.Studioprint];
    case ProductSlug._360s:
      return [];
    case ProductSlug.Artwork:
      return [];
  }
};
