import { useState } from "react";
import type { FC } from "react";

import { Button, Col, Flex, Row, Select } from "antd";

import type { ObjectCollection } from "@omi-lab/atlas-typescript";

import { OrganizationSearch } from "src/pkgs/TemplateCustomPreviews/components/OrganizationSearch";

import type {
  CreateCollectionBody,
  UpdateCollectionBody,
} from "components/CollectionTree/types";

import { CollectionTree } from "../../../components/CollectionTree";
import { useClientsStore } from "../../../store/clients";
import ObjectCollectionAppendModal from "../components/AppendModal";

export const ObjectCollections: FC = () => {
  const [objectCollectionsClient, filesClient] = useClientsStore((store) => [
    store.objectCollectionsClient,
    store.filesClient,
  ]);

  const [isOpen, setIsOpen] = useState(false);
  const [visibilityFilters, setVisibilityFilters] = useState([
    "public",
    "private",
    "hidden",
  ]);
  const [organizationId, setOrganizationId] = useState<string>();

  const createCollection = async (
    collection: CreateCollectionBody,
  ): Promise<ObjectCollection> => {
    const file = await filesClient
      .uploadFile({
        file: collection.thumbnail!,
        name: collection.thumbnail!.name,
      })
      .then((response) => response.data);

    return await objectCollectionsClient
      .createObjectCollection({
        body: {
          name: collection.name!,
          path: collection.path!,
          thumbnailFileId: file.id,
          visibility: collection.visibility,
        },
      })
      .then((response) => response.data as ObjectCollection);
  };

  const updateCollection = async (
    path: string,
    collection: UpdateCollectionBody,
  ): Promise<ObjectCollection> => {
    if (collection.thumbnail) {
      const file = await filesClient
        .uploadFile({
          file: collection.thumbnail!,
          name: collection.thumbnail!.name,
        })
        .then((response) => response.data);

      return await objectCollectionsClient
        .updateObjectCollection({
          objectCollectionPath: path,

          body: {
            isHighlighted: collection.isHighlighted,
            weight: collection.weight,
            name: collection.name!,
            thumbnailFileId: file.id,
            visibility: collection.visibility,
          },
        })
        .then((response) => response.data as ObjectCollection);
    } else {
      return await objectCollectionsClient
        .updateObjectCollection({
          objectCollectionPath: path,

          body: {
            isHighlighted: collection.isHighlighted,
            weight: collection.weight,
            name: collection.name!,
            visibility: collection.visibility,
          },
        })
        .then((response) => response.data as ObjectCollection);
    }
  };

  const deleteCollection = async (path: string) => {
    await objectCollectionsClient
      .deleteObjectCollection({
        objectCollectionPath: path,
      })
      .then((response) => response.data);
  };

  return (
    <Flex vertical gap={3}>
      <div style={{ padding: "15px" }}>
        <Row gutter={14} justify="start">
          <Col span={4}>
            <OrganizationSearch
              onChange={(orgId) => {
                setOrganizationId(orgId);
              }}
              onClear={() => {
                setOrganizationId(undefined);
              }}
            />
          </Col>
          <Col span={6}>
            <Select
              showSearch
              mode="multiple"
              placeholder="Visibility"
              onChange={(value) => {
                setVisibilityFilters(value);
              }}
              value={visibilityFilters}
              style={{ width: "100%" }}
            >
              {["public", "private", "hidden"].map((visibility) => (
                <Select.Option key={visibility} value={visibility}>
                  {visibility}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={2}>
            <Button type="primary" onClick={() => setIsOpen(true)}>
              Create
            </Button>
          </Col>
        </Row>
      </div>
      <Flex>
        <CollectionTree
          title="Object collections"
          subTitle="Click on a collection to create a new child"
          listCollections={(page, pageSize) =>
            objectCollectionsClient
              .listObjectCollections({
                page,
                pageSize,
                organizationId,
                visibilityIn: visibilityFilters,
                orderBy: "weightDesc",
              })
              .then((response) => response.data)
          }
          createCollection={createCollection}
          deleteCollection={deleteCollection}
          updateCollection={updateCollection}
        />
      </Flex>

      <ObjectCollectionAppendModal
        isOpen={isOpen}
        onCancel={() => setIsOpen(false)}
      />
    </Flex>
  );
};
